<template>
  <div>
    <v-breadcrumbs
      class="pt-1 pb-1"
      nuxt-link
      :items="items"
    ></v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: ['items'],
}
</script>
